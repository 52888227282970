import { Button, Grid, TextField } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useContext } from 'react';
import CustomerService from '../../services/CustomerService';
import { DialogActions } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';

export default function EditCustomerGroupDialog(props) {
  const { toggleEditTab, editData, getCustomerGroups } = props;
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [name, setName] = useState(editData.name);
  const [description, setDescription] = useState(editData.description);
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const handleUpdateCustomer = async () => {
    setLoadingIndicator(true);
    try {
      const data = {
        ...editData,
        name,
        description,
      };
      await CustomerService.updateCustomerGroups(editData.id, data);
      postAlertMessage({
        text: 'Customer gruop updated successfully',
        type: 'success',
      });
      getCustomerGroups();
    } catch (error) {
      postAlertMessage({ text: error.message, type: 'failed' });
    }
    setLoadingIndicator(false);
    toggleEditTab();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit Customer Group</h2>
          <CloseIcon onClick={toggleEditTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Group ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField label="Group Id" variant="outlined" size="small" fullWidth disabled value={editData.id} />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Description"
                name="Description"
                size="small"
                variant="outlined"
                fullWidth
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" size="small" onClick={toggleEditTab}>
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={name === '' || loadingIndicator}
              onClick={handleUpdateCustomer}
            >
              Update Group
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
