import React, { useState } from 'react';
import styles from './styles.module.css';
import { Button, Grid, TextField, DialogActions } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function EditOrderActionDialog(props) {
  const { handleClose, orderAction, updateOrderAction } = props;
  const [action, setAction] = useState(orderAction.action);
  const [order, setOrder] = useState(orderAction.order);

  const updateOrderActions = () => {
    updateOrderAction(orderAction, { action, order: Number(order) });
    handleClose();
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit order action</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} style={{ paddingTop: 5 }} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Action</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Action"
                variant="outlined"
                size="small"
                fullWidth
                value={action}
                onChange={(e) => {
                  setAction(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} style={{ paddingTop: 5 }} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Order</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Order"
                variant="outlined"
                size="small"
                type="number"
                fullWidth
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose} className={styles.closeBtn}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={action === '' || order === ''}
              onClick={updateOrderActions}
            >
              Update
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

export default EditOrderActionDialog;
