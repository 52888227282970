import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Grid } from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SearchIcon from '@material-ui/icons/Search';
import { AlertMessagesContext } from 'react-alert-messages';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { saveAs } from 'file-saver';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import ExpenseFormDialog from '../../popups/ExpenseFormDialog';
import ExpenseService from '../../../services/ExpenseService';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import setDelay from '../../../helpers/LoadingDelay';
import DataTable from '../../utils/DataTable/';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import styles from './styles.module.css';
import { SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import { featureIsExpired } from '../../utils/FeatureValidityExpire';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function Expenses() {
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [expenses, setExpenses] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showExpenseFormDialog, setShowExpenseFormDialog] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    startDate,
    endDate,
  });
  const [filteredExpenses, setFilteredExpenses] = useState(null);

  const subscriptionType = shop?.subscription?.type;
  const userHasWritePermission = user?.shop_permissions?.expense_permission === USER_PERMISSIONS.WRITE;

  const _getLoadExpenseRequestParams = useCallback(() => {
    return {
      start_date: DateTimeHelpers.startOfDay(startDate),
      end_date: DateTimeHelpers.endOfDay(endDate),
    };
  }, [startDate, endDate]);

  const getExpenses = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadExpenseRequestParams();
      const res = await ExpenseService.getExpenses(params);
      setExpenses(res);
      setFilteredExpenses(res);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setDelay(setLoadingIndicator);
  }, [_getLoadExpenseRequestParams, postAlertMessage]);

  const generateExpenses = () => {
    getExpenses();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      startDate,
      endDate,
    });
  };

  const handleExportToExcel = async () => {
    const startDateStr = DateTimeHelpers.getDateYMD(startDate);
    const endDateStr = DateTimeHelpers.getDateYMD(endDate);

    const csv = CsvHelper.getString(filteredExpenses, {
      id: 'ID',
      date: {
        title: 'Date',
        getValue: (expense) => DateTimeHelpers.convertDateToIsoDMY(new Date(expense.date)),
      },
      description: 'Description',
      amount: 'Amount',
    });
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `expenses-${startDateStr}-${endDateStr}.csv`);

    postAlertMessage({ text: 'Exported to CSV successfully', type: 'success' });
  };

  const _getExpenseAmountSum = (expenses) => {
    if (!expenses || !expenses.length) {
      return 0;
    }

    let sum = 0;
    expenses.forEach((expenses) => (sum += expenses.amount));
    return ShopsHelper.getAmountFormatted(shop, sum);
  };

  const handleDeleteClick = (data) => {
    setSelectedExpense(data);
    setShowDeleteConfirm(true);
  };

  const handleDeleteCancel = (data) => {
    setSelectedExpense(null);
    setShowDeleteConfirm(false);
  };

  const handleDeleteExpense = async () => {
    setLoadingIndicator(true);
    try {
      await ExpenseService.deleteExpense(selectedExpense.id);
      setExpenses(expenses.filter((expense) => expense.id !== selectedExpense.id));
      postAlertMessage({
        text: 'Expense deleted successfully',
        type: 'success',
      });
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setDelay(setLoadingIndicator);
  };

  const handleNewExpenseClick = () => {
    setShowExpenseFormDialog(true);
  };

  const handleEditClick = (expense) => {
    setSelectedExpense(expense);
    setShowExpenseFormDialog(true);
  };

  const handleFormCancel = () => {
    setShowExpenseFormDialog(false);
    setSelectedExpense(null);
  };

  const handleFormSuccess = () => {
    setShowExpenseFormDialog(false);
    getExpenses().then();
  };

  /// Obsolete methods
  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredExpenses(
      expenses.filter(
        (item) =>
          item.id.toString().includes(value.toLowerCase()) ||
          item.date.includes(value.toLowerCase()) ||
          item.description.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Date',
      id: 'date',
      type: 'callback',
      viewRender: (obj) => {
        return DateTimeHelpers.convertDateToIsoDMY(new Date(obj.date));
      },
    },
    {
      label: 'Description',
      id: 'description',
      type: 'text',
    },
    {
      label: 'Type',
      id: 'type',
      type: 'callback',
      viewRender: (obj) => {
        return obj.amount >= 0 ? 'Expense' : 'Income';
      },
    },
    {
      label: 'Amount',
      id: 'amount',
      type: 'floatAmount',
    },
    {
      label: 'Actions',
      id: 'actions',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            {userHasWritePermission && (
              <>
                <Tooltip title="Edit Category" onClick={() => handleEditClick(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
                <Tooltip title="Edit Category" onClick={() => handleDeleteClick(item)}>
                  <DeleteIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return startDate !== prevFilterData.startDate || endDate !== prevFilterData.endDate;
  }, [startDate, endDate, prevFilterData.startDate, prevFilterData.endDate]);

  useEffect(() => {
    if (shop?.expense_validity && !featureIsExpired(shop.expense_validity)) {
      generateExpenses();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const expenseAmountSum = _getExpenseAmountSum(filteredExpenses);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <h2 className={styles.title}>
          Expense<span className={styles.menuTitle}>Management</span>
        </h2>
        {subscriptionType &&
          subscriptionType === SUBSCRIPTION_TYPE.PREMIUM &&
          !featureIsExpired(shop.expense_validity) && (
            <div style={{ justifyContent: 'flex-end' }}>
              <div style={{ paddingBottom: '4px' }}>
                <label className={styles.label}>Export As</label>
              </div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a', marginRight: 5 }}
                onClick={handleExportToExcel}
              >
                <ImportExportIcon className={styles.actionBtnIcon} />
                CSV
              </Button>
            </div>
          )}
      </div>
      {featureIsExpired(shop?.expense_validity) && (
        <RestrictionInfo title={'Feature not available '} content={'To get Additional Expence support'} />
      )}
      {subscriptionType &&
        subscriptionType === SUBSCRIPTION_TYPE.PREMIUM &&
        !featureIsExpired(shop.expense_validity) && (
          <div className={styles.changeable}>
            <div className={styles.filterSec}>
              <div className={styles.headTitle}>
                <h2 className={styles.subTitle}>Expense</h2>
              </div>

              <div className={styles.filerInputSec}>
                {userHasWritePermission && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ backgroundColor: '#ff851b' }}
                    className={styles.actionBtn}
                    onClick={handleNewExpenseClick}
                  >
                    <NoteAddIcon className={styles.actionBtnIcon} />
                    New Expense
                  </Button>
                )}
                <div className={styles.searchSec}>
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      handleSearch(e.target.value);
                    }}
                    className={styles.searchInput}
                    placeholder="Search items"
                  />
                  <Tooltip title="Search" placement="bottom">
                    <SearchIcon className={styles.searchIcon} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className={styles.actionButtons}>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>From</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-from"
                      className={styles.dateBox}
                      value={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>To</label>
                </div>
                <div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableFuture
                      variant="outlined"
                      format="dd/MM/yyyy"
                      id="date-picker-to"
                      className={styles.dateBox}
                      value={endDate}
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'Change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className={styles.filterDiv}>
                <div style={{ paddingBottom: '4px' }}>
                  <label className={styles.label}>Generate Report</label>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.actionBtn}
                    style={{ backgroundColor: '#00a65a' }}
                    onClick={generateExpenses}
                    disabled={isShowGenerateButton === false}
                  >
                    <SyncIcon className={styles.actionBtnIcon} />
                    Generate
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

      {subscriptionType &&
        subscriptionType === SUBSCRIPTION_TYPE.PREMIUM &&
        shop.expense_validity !== null &&
        (isShowGenerateButton === null ? (
          <Info
            title={'Set filters and click generate button'}
            content={
              'Expenses are generated based on the filters. Please set filters and click generate button to get expenses.'
            }
          />
        ) : (
          filteredExpenses &&
          (filteredExpenses.length ? (
            <div className={styles.tableContainer}>
              <Grayout open={isShowGenerateButton} />
              <DataTable
                columns={headerData}
                rows={filteredExpenses || expenses}
                summary={{ type: 'Total', amount: expenseAmountSum }}
              />
            </div>
          ) : (
            !loadingIndicator && (
              <Info
                title={'No expenses with the current filter'}
                content={
                  'You have no expenses to list with current filter configuration. Please clear the filters or create a expenses'
                }
              />
            )
          ))
        ))}
      {showExpenseFormDialog && (
        <ExpenseFormDialog expense={selectedExpense} handleClose={handleFormCancel} handleSuccess={handleFormSuccess} />
      )}
      {showDeleteConfirm && (
        <ConfirmPopup
          data={selectedExpense}
          handleClose={handleDeleteCancel}
          handleOkay={handleDeleteExpense}
          alertTitle={'Confirm delete'}
          successBtnName={'Delete'}
          alertContent={"Deleted expense can't be restored, Are you sure to delete the expense?"}
        />
      )}
    </div>
  );
}

export default withConsoleBase(Expenses);
