import { Button, CardMedia, Grid, TextField } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ItemService from '../../services/ItemService';
import { DialogActions } from '@mui/material';
import { ShopContext } from '../../Context/ShopContext';
import { CONFIGS } from '../../const';
import { AlertMessagesContext } from 'react-alert-messages';

export default function AddCategoryDialog(props) {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { handleClose, onSuccess } = props;

  const [categoryName, setCategoryName] = useState('');
  const [kitchenIp, setKitchenIp] = useState(null);
  const [categoryDesc, setCategoryDesc] = useState('');
  const [order, setOrder] = useState(null);
  const [imageData, setImageData] = useState('');

  const uploadImage = (image, categoryId) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        const data = {
          image: reader.result,
        };
        try {
          await ItemService.uploadCategoryImage(data, categoryId);
          resolve();
        } catch (e) {
          reject(e);
        }
      };
    });
  };

  const handleAddCategory = async () => {
    try {
      const data = {
        name: categoryName,
        description: categoryDesc,
        kitchen_ip: kitchenIp,
        order,
      };
      const res = await ItemService.addItemCategory(data);
      if (data.image && data.image.size < CONFIGS.MAX_IMAGE_SIZE) {
        await uploadImage(imageData, res.id);
      }
      postAlertMessage({ text: 'Category created successfully', type: 'success' });
      handleClose();
      onSuccess();
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create New Category</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Category Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Name"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryName}
                onChange={(e) => {
                  setCategoryName(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Category Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Description"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryDesc}
                onChange={(e) => {
                  setCategoryDesc(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          {shop.kot_validity && shop.kot_validity != null && (
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Kitchen Ip</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TextField
                  label="Kitchen Ip"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={kitchenIp}
                  onChange={(e) => {
                    setKitchenIp(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Order</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Order"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          {shop && shop.image_support_validity && (
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Category Image</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div style={{ fontSize: 16, paddingBottom: 5 }}>150px * 150px size recommended</div>
                {imageData && <CardMedia image={URL.createObjectURL(imageData)} className={styles.categoryImg} />}
                {imageData && imageData.size > CONFIGS.MAX_IMAGE_SIZE && (
                  <div className={styles.imageSizeWarning}>
                    *Image size exceeds maximum limit. Upload file size less than 100KB
                  </div>
                )}

                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="upload"
                  onChange={(e) => {
                    setImageData(e.target.files[0]);
                  }}
                />
                <Button variant="contained" size="small" className={styles.uploadBtn}>
                  <label for="upload">{imageData ? 'change' : 'select'}</label>
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleClose}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ backgroundColor: '#00a65a' }}
              disabled={categoryName === ''}
              onClick={handleAddCategory}
            >
              Create Category
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
