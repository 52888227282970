import { Link } from 'react-router-dom';
import React, { useContext } from 'react';
import styles from './styles.module.css';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@material-ui/icons/People';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DevicesIcon from '@mui/icons-material/Devices';
import HomeIcon from '@mui/icons-material/Home';
import { Grid } from '@material-ui/core';
import { USER_PERMISSIONS, ROUTES } from '../../../const';
import { selectedTabContext } from '../../../Context/SelectedTabContext';
import { ShopContext } from '../../../Context/ShopContext';
import { UserContext } from '../../../Context/UserContext';
import { ThemeContext } from '../../../Context/ThemeContext';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import LayersIcon from '@mui/icons-material/Layers';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import CategoryIcon from '@mui/icons-material/Category';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import GroupsIcon from '@mui/icons-material/Groups';

export default function NavBar() {
  const { selectedTab, setSelectedTab, sections, setSections } = useContext(selectedTabContext);
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);

  const tabStyleActive = {
    backgroundColor: theme.color.active,
    color: '#fff',
  };

  const tabStyle = {
    color: '#fff',
  };

  const handleSelectedTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const handleChangeSections = (tab) => {
    setSections((prev) => ({ ...prev, [tab]: !prev[tab] }));
  };

  const salesTabRoutes = ['item-summary', 'bill-summary', 'itemised-bill'];

  return (
    <Grid container>
      <Grid item xs={12} className={styles.navBar}>
        <div
          style={{
            paddingTop: 10,
            paddingLeft: 10,
            background: theme.color.tertiary,
          }}
        >
          <span style={{ color: theme.color.sub_header }} className={styles.Shop}>
            {shop && shop.shop_name}
          </span>
        </div>
        <div style={{ background: theme.color.tertiary }} className={styles.profileSec}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <PersonIcon style={{ color: theme.color.sub_header, fontSize: '12px' }} />
            </span>
            <span style={{ color: theme.color.sub_header, width: 48 }} className={styles.username}>
              Username
            </span>
            <span className={styles.username}>: {user && user.username}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={`${styles.iconHolder} ${styles.navIcon}`}>
              <HomeIcon style={{ color: theme.color.sub_header, fontSize: '12px' }} />
            </span>
            <span style={{ color: theme.color.sub_header, width: 48 }} className={styles.username}>
              Shop ID
            </span>
            <span className={styles.username}>: {shop && shop?.id}</span>
          </div>
        </div>

        {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
          ''
        ) : (
          <Link to={ROUTES.DASHBOARD} style={{ textDecorationColor: 'transparent' }}>
            <div
              className={`${styles.navItem} ${styles.mt - 4}`}
              style={{
                ...(selectedTab === 'dashboard' ? tabStyleActive : tabStyle),
                borderBottom: selectedTab === 'dashboard' ? '1px solid rgb(255, 255, 255)' : 'none',
                padding: '15px 10px',
              }}
              onClick={() => {
                handleSelectedTabChange('dashboard');
              }}
            >
              <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                <DashboardIcon fontSize="small" className={styles.navBarIconColor} />
              </span>
              <span className={styles.title}>Dashboard</span>
            </div>
          </Link>
        )}

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          style={{
            ...(sections?.reports === true ? tabStyleActive : tabStyle),
            borderBottom: sections?.reports ? '1px solid rgb(255, 255, 255)' : 'none',
          }}
          onClick={() => {
            handleChangeSections('reports');
          }}
        >
          <span className={styles.title}>Reports</span>
          {sections.reports ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
        </div>
        {sections?.reports && (
          <>
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab' ? tabStyleActive : tabStyle}
                onClick={() => {
                  handleSelectedTabChange('salesTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <AssignmentIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Sales Reports</span>
              </div>
            )}
            {salesTabRoutes.includes(selectedTab) || selectedTab === 'salesTab' ? (
              <div
                style={{
                  backgroundColor: theme.color.active_bg,
                  padding: '4px',
                }}
              >
                <Link to={ROUTES.BILL_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Sales Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.ITEMISED_BILL} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Sales Items</span>
                  </div>
                </Link>
                <Link to={ROUTES.ITEM_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Item Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.DAY_WISE_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Day Wise Report</span>
                  </div>
                </Link>
                <Link to={ROUTES.STAFF_SALES_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Staff Sales Report</span>
                  </div>
                </Link>
                <Link to={ROUTES.CATEGORY_SALES_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Category Sales Report</span>
                  </div>
                </Link>
                <Link to={ROUTES.ORDER_TYPE_SALES_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Order Type Report</span>
                  </div>
                </Link>
                <Link to={ROUTES.RETURNED_BILL_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Returned Sales Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.RETURNED_ITEM_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Returned Item Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.RETURNED_SALE_ITEM_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Returned Sale Item</span>
                  </div>
                </Link>
                <Link to={ROUTES.SHIFT_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Shift Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PAYMENT_MODE_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Payments Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PROFIT_BILL_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Profit Sales Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PROFIT_ITEMISED_BILL} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Profit Sales Items</span>
                  </div>
                </Link>
                <Link to={ROUTES.PROFIT_ITEM_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Profit Item Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PROFIT_DAY_WISE_REPORT} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Profit Day Wise Report</span>
                  </div>
                </Link>
              </div>
            ) : null}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={
                  salesTabRoutes.includes(selectedTab) || selectedTab === 'purchaseTab' ? tabStyleActive : tabStyle
                }
                onClick={() => {
                  handleSelectedTabChange('purchaseTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <WysiwygIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Purchase Reports</span>
              </div>
            )}
            {salesTabRoutes.includes(selectedTab) || selectedTab === 'purchaseTab' ? (
              <div
                style={{
                  backgroundColor: theme.color.active_bg,
                  padding: '4px',
                }}
              >
                <Link to={ROUTES.PURCHASE_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Purchase Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PURCHASE_ITEM_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Purchase Items Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.VENDON_PURCHASE_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Vendor Purchase Summary</span>
                  </div>
                </Link>
              </div>
            ) : null}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <div
                className={`${styles.navItem} ${styles.mt - 1}`}
                style={salesTabRoutes.includes(selectedTab) || selectedTab === 'TaxTab' ? tabStyleActive : tabStyle}
                onClick={() => {
                  handleSelectedTabChange('TaxTab');
                }}
              >
                <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                  <ReceiptIcon fontSize="small" className={styles.navBarIconColor} />
                </span>
                <span className={styles.title}>Tax Reports</span>
              </div>
            )}
            {salesTabRoutes.includes(selectedTab) || selectedTab === 'TaxTab' ? (
              <div
                style={{
                  backgroundColor: theme.color.active_bg,
                  padding: '4px',
                }}
              >
                <Link to={ROUTES.SALES_TAX_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Sales Tax Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.PURCHASE_TAX_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>Purchase Tax Summary</span>
                  </div>
                </Link>
                <Link to={ROUTES.HSN_SUMMARY} style={{ textDecorationColor: 'transparent' }}>
                  <div className={styles.subMenu}>
                    <span>HSN Summary</span>
                  </div>
                </Link>
              </div>
            ) : null}
            {((user || {}).shop_permissions || {}).stock_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.STOCK_REPORT} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'stock-report' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('stock-report');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <SyncAltIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Stock Reports</span>
                </div>
              </Link>
            )}
          </>
        )}

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          style={{
            ...(sections?.operations ? tabStyleActive : tabStyle),
            borderBottom: sections?.operations ? '1px solid rgb(255, 255, 255)' : 'none',
          }}
          onClick={() => {
            handleChangeSections('operations');
          }}
        >
          <span className={styles.title}>Operations</span>
          {sections.operations ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
        </div>
        {sections?.operations && (
          <>
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.PURCHASE_HISTORY} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'purchase-history' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('purchase-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingCartIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Purchases</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).expense_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.EXPENSES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'expenses' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('expenses');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <CreditCardIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Expenses</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).damage_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.DAMAGE_HISTORY} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'damage-history' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('damage-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <AssessmentIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Stock Damages</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).reports_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.ORDER_ACTIONS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'order-actions' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('order-actions');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <LayersIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Order Actions</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.CUSTOMERS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'customers' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('customers');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <PeopleIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Customers</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) :  (
              <Link to={ROUTES.CUSTOMER_GROUP} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'customer-group' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('customer-group');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <GroupsIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Groups</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.VENDORS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'vendors' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('vendors');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <InventoryIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Vendors</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).purchase_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.MANAGE_SALES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={styles.navItem}
                  style={selectedTab === 'sales-history' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('sales-history');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingBasketIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Sales</span>
                </div>
              </Link>
            )}
          </>
        )}

        <div
          className={`${styles.navSectionItem} ${styles.mt - 1}`}
          style={{
            ...(sections?.management ? tabStyleActive : tabStyle),
            borderBottom: sections?.management ? '1px solid rgb(255, 255, 255)' : 'none',
          }}
          onClick={() => {
            handleChangeSections('management');
          }}
        >
          <span className={styles.title}>Management</span>
          {sections.management ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}
        </div>
        {sections?.management && (
          <>
            {((user || {}).shop_permissions || {}).products_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.ITEMS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'products' ? tabStyleActive : tabStyle}
                  onClick={() => { handleSelectedTabChange('products');}}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <ShoppingBagIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Products</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.CATEGORIES} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'categories' ? tabStyleActive : tabStyle}
                  onClick={() => { handleSelectedTabChange('categories');}}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <CategoryIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Categories</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.SALES_PERSON} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'salesperson' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('salesperson');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <PersonIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Salespersons</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).devices_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.TERMINALS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'terminals' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('terminals');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <DevicesIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Terminals</span>
                </div>
              </Link>
            )}
            {((user || {}).shop_permissions || {}).shop_permission === USER_PERMISSIONS.DENY ? (
              ''
            ) : (
              <Link to={ROUTES.SETTINGS} style={{ textDecorationColor: 'transparent' }}>
                <div
                  className={`${styles.navItem} ${styles.mt - 4}`}
                  style={selectedTab === 'settings' ? tabStyleActive : tabStyle}
                  onClick={() => {
                    handleSelectedTabChange('settings');
                  }}
                >
                  <span className={`${styles.iconHolder} ${styles.navIcon}`}>
                    <SettingsIcon fontSize="small" className={styles.navBarIconColor} />
                  </span>
                  <span className={styles.title}>Settings</span>
                </div>
              </Link>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}
