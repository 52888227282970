import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import SearchIcon from '@material-ui/icons/Search';
import PurchaseService from '../../../services/PurchaseService';
import Loader from '../../utils/Loading';
import Info from '../../utils/Alert/Info';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import { ShopContext } from '../../../Context/ShopContext';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import { AlertMessagesContext } from 'react-alert-messages';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { saveAs } from 'file-saver';
import CsvHelper from '../../../helpers/CsvHelper';
import SyncIcon from '@mui/icons-material/Sync';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import PrintIcon from '@mui/icons-material/Print';

function PurchaseTaxSummary() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [filterFromDate, setFilterFromDate] = useState(new Date());
  const [filterToDate, setFilterToDate] = useState(new Date());

  const [searchText, setSearchText] = useState('');
  const [purchases, setPurchase] = useState('');
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
  });

  const CSV_COLUMNS = {
    id: 'Id',
    invoice_no: 'Invoice No',
    bill_date: 'Bill Date',
    date: 'Date',
    vendor: {
      title: 'Vendor Name',
      getValue: (purchaseItem) => purchaseItem?.vendor?.name ?? purchaseItem?.vendor_name_old ?? '-',
    },
    plu: {
      title: 'PLU',
      getValue: (purchaseItem) => purchaseItem?.item?.plu,
    },
    name: {
      title: 'Item Name',
      getValue: (purchaseItem) => purchaseItem?.item?.name,
    },
    rate: {
      title: 'Cost',
      getValue: (purchaseItem) => purchaseItem?.item?.rate,
    },
    quantity: {
      title: 'Quantity',
      getValue: (purchaseItem) => purchaseItem?.item?.quantity,
    },
    vat: {
      title: 'Tax',
      getValue: (purchaseItem) => purchaseItem?.item?.vat,
    },
    total: {
      title: 'Total',
      getValue: (purchaseItem) => purchaseItem?.item?.rate * purchaseItem?.item?.quantity + purchaseItem?.item?.vat,
    },
    total_amount: 'Purchase Total',
  };

  const toCsvBtnPressed = async () => {
    const csvFormattedPurchases = purchases.flatMap((obj) =>
      obj.items.map((item, index) => ({
        id: obj.id,
        invoice_no: obj.invoice_no,
        bill_date: obj.bill_date,
        date: obj.date,
        vendor: obj.vendor,
        vendor_name_old: obj.vendor_name_old,
        total_amount: index === 0 ? obj.total_amount : undefined,
        item: {
          ...item,
          name: item.item.name,
        },
      }))
    );

    const csv = CsvHelper.getString(csvFormattedPurchases, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `purchase-tax-summary-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const headerData = [
    {
      label: 'Vendor',
      id: 'vendor',
      type: 'callback',
      viewRender: (item) => item?.vendor?.name ?? item?.vendor_name_old ?? '-',
    },
    {
      label: 'Purchase Date',
      id: 'date',
      type: 'text',
    },
    {
      label: 'Bill Date',
      id: 'bill_date',
      type: 'text',
    },
    {
      label: 'Invoice No',
      id: 'invoice_no',
      type: 'text',
    },
    {
      label: 'Total Tax',
      id: 'tax',
      type: 'callback',
      viewRender: (item) => {
        let totalTax = (item?.items || []).reduce((acc, i) => acc + (i.vat || 0), 0);
        return totalTax;
      },
    },
    {
      label: 'Total Amount',
      id: 'total_amount',
      type: 'floatAmount',
    },
  ];

  const _getLoadPurchaseRequestParams = useCallback(() => {
    return {
      date_start: DateTimeHelpers.getDateYMD(filterFromDate),
      date_end: DateTimeHelpers.getDateYMD(filterToDate),
    };
  }, [filterFromDate, filterToDate]);

  const getPurchase = useCallback(async () => {
    setLoadingIndicator(true);
    try {
      const params = _getLoadPurchaseRequestParams();
      const res = await PurchaseService.getPurchaseDetails(params);
      setPurchase(res);
      setFilteredPurchases(res);
    } catch (err) {
      postAlertMessage({ text: err.message, type: 'failed' });
    }
    setLoadingIndicator(false);
  }, [_getLoadPurchaseRequestParams, postAlertMessage]);

  const generatePurchaseHistory = () => {
    getPurchase();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
    });
  };

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return filterFromDate !== prevFilterData.filterFromDate || filterToDate !== prevFilterData.filterToDate;
  }, [filterFromDate, filterToDate, prevFilterData.filterFromDate, prevFilterData.filterToDate]);

  useEffect(() => {
    generatePurchaseHistory();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const handleSearch = (value) => {
    setSearchText(value);
    setFilteredPurchases((purchases || []).filter((data) => data.name.toLowerCase().includes(value)));
  };

  const _getPurchasesTaxReportSummart = (purchaseData) => {
    let totalAmount = 0;
    let totalTax = 0;
    (purchaseData || []).forEach((data) => {
      totalAmount += data?.total_amount;
      totalTax += (data.items || []).reduce((acc, dt) => acc + (dt?.vat || 0), 0);
    });
    return {
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
    };
  };

  const summary = _getPurchasesTaxReportSummart(filteredPurchases);

  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />
      <div className={styles.titleSec}>
        <span className={styles.title}>
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a', marginRight: 5 }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            Csv
          </Button>
        </div>
      </div>

      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Purchase Tax Summary Report for{'  '}
              {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search purchase"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  variant="outlined"
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Load Purchases</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generatePurchaseHistory}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click load button'}
          content={
            'Purchase history are generated based on the filters. Please set filters and click load purchase button to get purchase history.'
          }
        />
      ) : (
        purchases &&
        (filteredPurchases && filteredPurchases.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={filteredPurchases}
              summary={{ vendor: 'Total', total_amount: summary.totalAmount, total_tax: summary.totalTax }}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              title={'You have no purchases to list'}
              content={"You haven't any purchase to list with given properties"}
            />
          )
        ))
      )}
    </div>
  );
}

export default withConsoleBase(PurchaseTaxSummary);
