import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Grid } from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import TableFormDialog from '../../popups/TableFormDialog';
import TableService from '../../../services/TableService';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Loader from '../../utils/Loading';
import SearchIcon from '@material-ui/icons/Search';
import Info from '../../utils/Alert/Info';
import RestrictionInfo from '../../utils/Alert/RestrictionInfo';
import { ShopContext } from '../../../Context/ShopContext';
import ConfirmPopup from '../../utils/Alert/ConfirmPopup';
import { UserContext } from '../../../Context/UserContext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableEditDialog from '../../popups/EditTableDialog';
import { SUBSCRIPTION_TYPE, USER_PERMISSIONS } from '../../../const';
import { AlertMessagesContext } from 'react-alert-messages';

function Tables() {
  const { postAlertMessage } = useContext(AlertMessagesContext);
  const { shop } = useContext(ShopContext);
  const { user } = useContext(UserContext);

  const [tables, setTables] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [tablesFiltered, setTablesFiltered] = useState(null);

  const subscriptionType = shop?.subscription?.type;
  const userHasSalesPersonWritePermission = user?.shop_permissions?.sales_persons_permission === USER_PERMISSIONS.WRITE;

  const handleDeleteClick = (tables) => setSelectedForDelete(tables);
  const handleAddClick = () => setShowAddForm(true);
  const handleEditClick = (tables) => setSelectedForEdit(tables);

  const handleDeleteTable = async () => {
    setLoading(true);
    try {
      await TableService.deleteTables(selectedForDelete.id);
      setTables(tables.filter((table) => table.id !== selectedForDelete.id));
      postAlertMessage({ text: 'Table deleted successfully', type: 'success' });
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filterResponse = tables
      ? tables.filter(
          (table) => table.name.toLowerCase().includes(value.toLowerCase()) || table.id.toString().includes(value)
        )
      : [];
    setTablesFiltered(filterResponse);
  };

  const getTables = useCallback(async () => {
    setLoading(true);
    try {
      const table = await TableService.getTables();
      setTables(table);
    } catch (error) {
      postAlertMessage({ text: error?.message, type: 'failed' });
    }
    setLoading(false);
  }, [postAlertMessage]);

  const headerData = [
    {
      label: 'ID',
      id: 'id',
      type: 'text',
    },
    {
      label: 'Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Actions',
      id: 'actions',
      type: 'callback',
      viewRender: (item) => {
        return (
          <Grid className={styles.btnGroup}>
            {userHasSalesPersonWritePermission && (
              <>
                <Tooltip title="Edit Table" onClick={() => handleEditClick(item)}>
                  <EditIcon className={styles.iconButton} />
                </Tooltip>
                <Tooltip title="Delete Table" onClick={() => handleDeleteClick(item)}>
                  <DeleteIcon className={styles.iconButton} />
                </Tooltip>
              </>
            )}
          </Grid>
        );
      },
    },
  ];

  const featureIsExpired = () => {
    const currentDate = new Date();
    const validityDate = new Date(shop.kot_validity);
    return shop.kot_validity == null || currentDate > validityDate;
  };

  useEffect(() => {
    if (!shop?.id || featureIsExpired()) {
      return;
    }
    getTables();
    //eslint-disable-next-line
  }, [getTables, shop?.id, shop?.kot_validity]);

  useEffect(() => {
    handleSearch(searchText);
    //eslint-disable-next-line
  }, [tables]);

  return (
    <div className={styles.contentLayout}>
      <Loader isOpen={isLoading} />
      <div className={styles.settingsGrid}>
        <div className={styles.BillHeaderDiv}>
          <span className={styles.pageHead}>Manage Tables</span>
          <div className={styles.filerInputSec}>
            {userHasSalesPersonWritePermission && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: '#ff851b' }}
                className={styles.actionBtn}
                onClick={handleAddClick}
              >
                <NoteAddIcon className={styles.actionBtnIcon} />
                New Table
              </Button>
            )}
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="search items"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        {shop && featureIsExpired() ? (
          <RestrictionInfo
            title={'KOT Feature not available '}
            content={'To get KOT support options, contact support team.'}
          />
        ) : (
          <>
            {subscriptionType &&
              shop.kot_validity !== null &&
              subscriptionType !== SUBSCRIPTION_TYPE.BASIC &&
              tables &&
              (tables.length ? (
                <DataTable columns={headerData} rows={tablesFiltered ? tablesFiltered : tables} />
              ) : (
                <Info
                  title={'No tables to list'}
                  content={
                    'You have no table to list with current filter configuration. Please clear the filters or create a new table'
                  }
                />
              ))}

            {showAddForm && (
              <TableFormDialog
                handleClose={() => setShowAddForm(false)}
                handleSuccess={(newTable) => {
                  setShowAddForm(false);
                  setTables([...tables, newTable]);
                }}
              />
            )}
            {selectedForDelete && (
              <ConfirmPopup
                data={selectedForDelete}
                handleClose={() => setSelectedForDelete(null)}
                handleOkay={handleDeleteTable}
                alertTitle={'Confirm delete'}
                successBtnName={'delete'}
                alertContent={"Deleted table can't be restored, do you want to delete the table ? "}
              />
            )}
            {selectedForEdit && (
              <TableEditDialog
                table={selectedForEdit}
                handleClose={() => {
                  setSelectedForEdit(null);
                }}
                handleSuccess={(updatedTable) => {
                  setSelectedForEdit(null);
                  const _tables = tables.map((table) => (table.id === selectedForEdit.id ? updatedTable : table));
                  setTables(_tables);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Tables;
