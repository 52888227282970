import React, { useState } from 'react';
import styles from './styles.module.css';
import { DialogActions } from '@mui/material';
import { Button, Grid, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function AddPaymentPopup(props) {
  const { togglePaymentTab, addPaymentToVendor, currentBalance } = props;
  const [debitAmount, setDebitAmount] = useState();
  const [finalBalance, setFinalBalance] = useState();

  const addPayment = () => {
    let data = { debit: debitAmount };
    addPaymentToVendor(data);
  };

  const calculateBydebitAmount = (amt) => {
    let balance = currentBalance + Number(amt);
    setDebitAmount(amt);
    setFinalBalance(balance);
  };

  const calculateByFinalBalance = (balance) => {
    let amt = balance - currentBalance;
    setDebitAmount(amt);
    setFinalBalance(balance);
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Add payment</h2>
          <CloseIcon
            onClick={() => {
              togglePaymentTab('');
            }}
          />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Balance</h3>
            </Grid>
            <Grid item xs={8} sm={8} spacing={2} className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>{currentBalance}</h3>
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Debit Amount</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                value={debitAmount}
                onChange={(e) => calculateBydebitAmount(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Final Balance</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                variant="outlined"
                type="number"
                size="small"
                className={styles.numberInput}
                value={finalBalance}
                onChange={(e) => calculateByFinalBalance(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={togglePaymentTab}
              className={styles.closeBtn}
            >
              close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={debitAmount === ''}
              onClick={addPayment}
            >
              Add Payment
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentPopup;
