import { Button, CardMedia, Grid, TextField, Checkbox } from '@material-ui/core';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useContext, useEffect } from 'react';
import ItemService from '../../services/ItemService';
import { ShopContext } from '../../Context/ShopContext';
import { DialogActions } from '@mui/material';
import { AlertMessagesContext } from 'react-alert-messages';

export default function EditCategoryDialog(props) {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const { handleClose, category, onSuccess } = props;

  const [categoryName, setCategoryName] = useState(category?.name || '');
  const [categoryDesc, setCategoryDesc] = useState(category?.desc || '');
  const [kitchenIp, setKitchenIp] = useState(category?.kitchen_ip || '');
  const [imageData, setImageData] = useState('');
  const [isAvailable, setIsAvailable] = useState(category?.is_available || false);
  const [order, setOrder] = useState(category?.order || '');
  const [isNotifyForOnlineOrder, setIsNotifyForOnlineOrder] = useState(category?.is_notify_for_online_order || false);


  useEffect(() => {
    setCategoryName(category?.name || '');
    setCategoryDesc(category?.desc || '');
    setKitchenIp(category?.kitchen_ip || '');
    setOrder(category?.order || '');
    setIsAvailable(category?.is_available || false);
    setIsNotifyForOnlineOrder(category?.is_notify_for_online_order || false); 
  }, [category]);

  const uploadImage = async (image, categId) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onloadend = async () => {
      const data = { image: reader.result };
      await ItemService.uploadCategoryImage(data, categId);
    };
  };

  const handleEditCategory = async () => {
    try {
      const data = {
        name: categoryName,
      };
      
      if (categoryDesc) data.description = categoryDesc;
      if (kitchenIp) data.kitchen_ip = kitchenIp;
      if (isAvailable !== undefined) data.is_available = isAvailable;
      if (isNotifyForOnlineOrder !== undefined) data.is_notify_for_online_order = isNotifyForOnlineOrder;
      if (order) data.order = order;
      
      
      await ItemService.editItemCategory(data, category.id);

      if (imageData.size < 101000) {
        uploadImage(imageData, category.id);
      }

      postAlertMessage({ text: 'Category updated successfully', type: 'success' });

      onSuccess();
      handleClose(); 
    } catch (err) {
      console.error("Error Updating Category:", err);
      postAlertMessage({ text: err.message, type: 'failed' });
    }
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Edit Category</h2>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Category Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Name"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Category Description</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Category Description"
                variant="outlined"
                size="small"
                fullWidth
                value={categoryDesc}
                onChange={(e) => setCategoryDesc(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Kitchen Ip</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Kitchen Ip"
                variant="outlined"
                size="small"
                fullWidth
                value={kitchenIp}
                onChange={(e) => {
                  setKitchenIp(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Order</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Order"
                variant="outlined"
                type="number"
                size="small"
                fullWidth
                value={order}
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Available for Sale</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <div className={styles.checkboxContainer}>
                <Checkbox checked={isAvailable} onChange={() => setIsAvailable(!isAvailable)} />
                <span className={styles.checkboxLabel}>
                  {isAvailable ? "Available" : "Not Available"}
                </span>
              </div>
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Notification for Online Order</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <div className={styles.checkboxContainer}>
                <Checkbox checked={isNotifyForOnlineOrder} onChange={() => setIsNotifyForOnlineOrder(!isNotifyForOnlineOrder)} />
                <span className={styles.checkboxLabel}>
                  {isNotifyForOnlineOrder ? "Notifications Required" : "Notifications Not Required"}
                </span>
              </div>
            </Grid>
          </Grid>
          {shop && shop.image_support_validity && (
            <Grid container className={styles.inputRow} alignItems="center">
              <Grid item xs={4} sm={4} justify="flex-end" className={styles.inputLabelContainer}>
                <h3 className={styles.inputLabel}>Category Image</h3>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div className={styles.imageContainer}>
                  <CardMedia
                    image={imageData ? URL.createObjectURL(imageData) : category?.image}
                    className={styles.image}
                  />
                </div>
                {imageData && imageData.size > 101000 && (
                  <div className={styles.imageSizeWarning}>*Upload file size less than 100KB</div>
                )}
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="upload"
                  onChange={(e) => setImageData(e.target.files[0])}
                />
                <Button variant="contained" className={styles.uploadBtn}>
                  <label htmlFor="upload">Select</label>
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={handleClose} className={styles.closeBtn}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" disabled={!categoryName} onClick={handleEditCategory}>
              Update
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
