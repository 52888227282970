import React, { useCallback, useContext, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Button,
  MenuItem,
  TextField,
  ListItemText,
  OutlinedInput,
  Checkbox,
  Select,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import styles from './styles.module.css';
import DataTable from '../../utils/DataTable';
import ItemSummaryReportService from '../../../services/ItemSummaryReportService';
import SalespersonsService from '../../../services/SalesPersonService';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SyncIcon from '@mui/icons-material/Sync';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';
import ItemService from '../../../services/ItemService';
import Info from '../../utils/Alert/Info';
import Loader from '../../utils/Loading';
import setDelay from '../../../helpers/LoadingDelay';
import withConsoleBase from '../../utils/ConsoleBase/withConsoleBase';
import AlertHelper from '../../../helpers/AlertHelper';
import Error from '../../utils/Alert/Error';
import { ShopContext } from '../../../Context/ShopContext';
import { AlertMessagesContext } from 'react-alert-messages';
import CsvHelper from '../../../helpers/CsvHelper';
import { filterEndDate } from '../../utils/FilterEndDate';
import PrintIcon from '@mui/icons-material/Print';
import ShopsHelper from '../../../helpers/ShopsHelper';
import Grayout from '../../utils/GrayOut/Grayout';
import TerminalService from '../../../services/TerminalServices';
import { SelectFilterStyle } from '../../../const';
import DateLimitDialog from '../../popups/DateLimitDialog';

function ReturnedItemSummary() {
  const { shop } = useContext(ShopContext);
  const { postAlertMessage } = useContext(AlertMessagesContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(getDefaultStartTime());
  const [filterToDate, setFilterToDate] = useState(filterEndDate());
  const [filterCategory, setFilterCategory] = useState('all');
  const [gst, setGst] = useState('all');
  const [items, setItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [categories, setCategories] = useState('');
  const [vats, setVats] = useState('');
  const [errorMsg, setErrorMsg] = useState();
  const [terminals, setTerminals] = useState([]);
  const [filterTerminal, setFilterTerminal] = useState([]);
  const [salesPersons, setSalesPersons] = useState([]);
  const [filterSalesperson, setFilterSalesperson] = useState([]);
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(null);
  const [prevFilterData, setPrevFilterData] = useState({
    filterFromDate,
    filterToDate,
    filterCategory,
    gst,
  });
  const [dateLimitPopup, setDateLimitPopup] = useState(false);

  const CSV_COLUMNS = {
    id: 'PLU',
    name: 'Item Name',
    hsn: 'HSN',
    price: 'Unit Price',
    tax: 'Tax(%)',
    tax_amount: 'Tax Amount',
    cess: 'Additional Charges(%)',
    cess_amount: 'Additional Charges',
    sold_quantity: 'Sold Qty',
    unit: 'Unit',
    total: 'Total Price',
  };

  function getDefaultStartTime() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }

  const isAnyChangeOnReportsFilters = useCallback(() => {
    return (
      filterFromDate !== prevFilterData.filterFromDate ||
      filterToDate !== prevFilterData.filterToDate ||
      filterCategory !== prevFilterData.filterCategory ||
      gst !== prevFilterData.gst ||
      filterTerminal !== prevFilterData.filterTerminal ||
      filterSalesperson !== prevFilterData.filterSalesperson
    );
  }, [
    filterFromDate,
    filterToDate,
    filterCategory,
    gst,
    filterTerminal,
    filterSalesperson,
    prevFilterData.filterFromDate,
    prevFilterData.filterToDate,
    prevFilterData.filterCategory,
    prevFilterData.gst,
    prevFilterData.filterTerminal,
    prevFilterData.filterSalesperson,
  ]);

  useEffect(() => {
    generateItemSummaryReports();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategories();
    // getMachineName();
    getSalespersons();
    getTerminals();
    getVat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnReportsFilters());
  }, [isAnyChangeOnReportsFilters, isShowGenerateButton]);

  const generateItemSummaryReports = () => {
    loadItemSummaryReports();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      filterFromDate,
      filterToDate,
      filterCategory,
      gst,
      filterSalesperson,
      filterTerminal,
    });
  };

  const loadItemSummaryReports = async ({
    startTime = filterFromDate,
    endTime = filterToDate,
    category = filterCategory,
    vat = gst,
    cashier_ids = filterSalesperson,
    terminal_ids = filterTerminal,
  } = {}) => {
    if (DateTimeHelpers.reportDateLimit(startTime, endTime)) {
      setDateLimitPopup(true);
      return;
    }
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(startTime.getTime() - startTime.getTimezoneOffset() * 60000);
      const timezoneFreeEndTime = new Date(endTime.getTime() - endTime.getTimezoneOffset() * 60000);

      const data = {
        filter_start_time: timezoneFreeStartTime,
        filter_end_time: timezoneFreeEndTime,
        category_id: category === 'all' ? undefined : category,
        filter_vat: vat === 'all' ? '' : [vat],
        terminal_ids: terminal_ids.length ? terminal_ids : null,
        cashier_ids: cashier_ids.length ? cashier_ids : null,
      };

      const resp = await ItemSummaryReportService.getReturnedItemSummaryReport(data);
      setItems(resp);
      setDelay(setLoadingIndicator);
    } catch (err) {
      AlertHelper.setShortMessage(setErrorMsg, err.message);
    }
    setLoadingIndicator(false);
  };

  const getCategories = async () => {
    const res = await ItemService.getItemCategories();
    setCategories(res);
  };

  const getVat = async () => {
    const res = await ItemSummaryReportService.getVat();
    setVats(res);
  };

  const getSalespersons = async () => {
    const res = await SalespersonsService.getSalesPersons();
    setSalesPersons(res);
  };

  const getTerminals = async () => {
    const res = await TerminalService.getTerminals();
    setTerminals(res);
  };

  const toCsvBtnPressed = async () => {
    const csv = CsvHelper.getString(items, CSV_COLUMNS);
    const blob = new Blob([csv], {
      type: 'text/csv',
    });
    saveAs(blob, `returned-item-summary-${new Date().toISOString()}.csv`);

    postAlertMessage({
      text: 'Exported to excel successfully',
      type: 'success',
    });
  };

  const _getItemsFiltered = (items, searchText) => {
    if (!searchText) {
      return items;
    }

    return items.filter(
      (item) =>
        item.plu.toString().includes(searchText.toLowerCase()) ||
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const _getItemsReportSummary = (items) => {
    let totalAmount = 0;
    let totalTax = 0;
    let totalAdditionalTax = 0;
    items.forEach((item) => {
      totalAmount += item.total;
      totalTax += item.tax_amount;
      totalAdditionalTax += item.cess_amount;
    });

    return {
      totalAmount: ShopsHelper.getAmountFormatted(shop, totalAmount),
      totalTax: ShopsHelper.getAmountFormatted(shop, totalTax),
      totalAdditionalTax: ShopsHelper.getAmountFormatted(shop, totalAdditionalTax),
    };
  };

  const headerData = [
    {
      label: 'PLU',
      id: 'plu',
      type: 'text',
    },
    {
      label: 'Item Name',
      id: 'name',
      type: 'text',
    },
    {
      label: 'Unit Price',
      id: 'price',
      type: 'floatAmount',
    },
    {
      label: 'Tax(%)',
      id: 'tax',
      type: 'text',
    },
    {
      label: 'Tax Amount',
      id: 'tax_amount',
      type: 'floatAmount',
    },
    {
      label: 'Additional Tax(%)',
      id: 'cess',
      type: 'text',
    },
    {
      label: 'Additional Tax',
      id: 'cess_amount',
      type: 'floatAmount',
    },
    {
      label: 'Sold Quantity',
      id: 'sold_quantity',
      type: 'callback',
      viewRender: (item) => {
        if (item.sold_quantity === '' || isNaN(item.sold_quantity)) {
          return item.sold_quantity;
        }
        return `${ShopsHelper.getAmountFormatted(shop, item.sold_quantity)} ${item.unit || ''}`;
      },
    },
    {
      label: 'Total Amount',
      id: 'total',
      type: 'callback',
      viewRender: (item) => {
        return `${shop?.currency || ''} ${item.total}`;
      },
    },
  ];

  const filteredItems = _getItemsFiltered(items, searchText);
  const summary = _getItemsReportSummary(filteredItems);
  return (
    <div className={styles.contentWrapper}>
      <Loader isOpen={loadingIndicator} />

      <div className={styles.titleSec}>
        <span className={styles.title}>
          {' '}
          Reports<span className={styles.menuTitle}>Generation</span>
        </span>
        <div style={{ justifyContent: 'flex-end', marginRight: '10px' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Print Report</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionBtnIcon} />
            Print
          </Button>
        </div>
        <div style={{ justifyContent: 'flex-end' }}>
          <div style={{ paddingBottom: '4px' }}>
            <label className={styles.label}>Export As</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={styles.actionBtn}
            style={{ backgroundColor: '#00a65a' }}
            onClick={toCsvBtnPressed}
          >
            <ImportExportIcon className={styles.actionBtnIcon} />
            CSV
          </Button>
        </div>
      </div>
      <div className={styles.changeable}>
        <div className={styles.filterSec}>
          <div className={styles.headTitle}>
            <h2 className={styles.subTitle}>
              Returned Item Summary Report of {DateTimeHelpers.convertDateToDMY(filterFromDate)}
              {'  '}to{'  '}
              {DateTimeHelpers.convertDateToDMY(filterToDate)}
            </h2>
          </div>
          <div className={styles.filerInputSec}>
            <div className={styles.searchSec}>
              <input
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                className={styles.searchInput}
                placeholder="Search by plu, name"
              />
              <Tooltip title="Search" placement="bottom">
                <SearchIcon className={styles.searchIcon} />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={styles.actionButtons}>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>From</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-from"
                  className={styles.dateBox}
                  value={filterFromDate}
                  onChange={(date) => {
                    setFilterFromDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>To</label>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  format="dd/MM/yyyy"
                  id="date-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change date',
                  }}
                />
                <KeyboardTimePicker
                  disableFuture
                  id="time-picker-to"
                  className={styles.dateBox}
                  value={filterToDate}
                  onChange={(date) => {
                    setFilterToDate(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'Change Time',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Category</label>
            </div>
            <div>
              <TextField
                select
                name="gst"
                size="small"
                variant="outlined"
                defaultValue={filterCategory}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setFilterCategory(event.target.value);
                }}
              >
                {categories &&
                  categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Terminal</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterTerminal}
                  onChange={(e) => setFilterTerminal(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterTerminal) => {
                    if (filterTerminal.length === 0) return <span>All</span>;
                    const selectedNames = filterTerminal.map((id) => terminals.find((tr) => tr.id === id).name);
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {terminals.map((terminal) => (
                    <MenuItem key={terminal.id} value={terminal.id}>
                      <Checkbox checked={filterTerminal.includes(terminal.id)} color="primary" />
                      <ListItemText primary={terminal.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Salesperson</label>
            </div>
            <div>
              <FormControl sx={{ m: 1, width: 300 }}>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  displayEmpty
                  value={filterSalesperson}
                  onChange={(e) => setFilterSalesperson(e.target.value)}
                  input={<OutlinedInput />}
                  style={{ width: 135, height: 40 }}
                  renderValue={(filterSalesperson) => {
                    if (filterSalesperson.length === 0) return <span>All</span>;
                    const selectedNames = filterSalesperson.map(
                      (id) => salesPersons.find((person) => person.id === id).name
                    );
                    return selectedNames.join(', ');
                  }}
                  MenuProps={SelectFilterStyle}
                >
                  {salesPersons.map((salesperson) => (
                    <MenuItem key={salesperson.id} value={salesperson.id}>
                      <Checkbox checked={filterSalesperson.includes(salesperson.id)} color="primary" />
                      <ListItemText primary={salesperson?.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Tax</label>
            </div>
            <div>
              <TextField
                select
                name="category"
                size="small"
                variant="outlined"
                defaultValue={gst}
                color="primary"
                className={styles.textfeild}
                onChange={(event) => {
                  setGst(event.target.value);
                }}
              >
                {vats &&
                  vats.map((vat) => (
                    <MenuItem key={vat} value={vat}>
                      {vat}
                    </MenuItem>
                  ))}
                <MenuItem value="all">All</MenuItem>
              </TextField>
            </div>
          </div>
          <div className={styles.filterDiv}>
            <div style={{ paddingBottom: '4px' }}>
              <label className={styles.label}>Generate Report</label>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={styles.actionBtn}
                style={{ backgroundColor: '#00a65a' }}
                onClick={generateItemSummaryReports}
                disabled={isShowGenerateButton === false}
              >
                <SyncIcon className={styles.actionBtnIcon} />
                Generate
              </Button>
            </div>
          </div>
        </div>
      </div>
      {errorMsg && (
        <div className={styles.marginTop}>
          <Error title={errorMsg} />
        </div>
      )}

      {isShowGenerateButton === null ? (
        <Info
          title={'Set filters and click generate button'}
          content={
            'Reports are generated based on the filters. Please set filters and click generate button to the generate report.'
          }
        />
      ) : (
        items &&
        (filteredItems && filteredItems.length ? (
          <div className={styles.tableContainer}>
            <Grayout open={isShowGenerateButton} />
            <DataTable
              columns={headerData}
              rows={filteredItems}
              rowKey="id"
              summary={{
                name: 'Total',
                total: `${shop?.currency} ${summary.totalAmount}`,
                tax_amount: `${shop?.currency || ''} ${summary.totalTax}`,
                cess_amount: `${shop?.currency || ''} ${summary.totalAdditionalTax}`,
              }}
            />
          </div>
        ) : (
          !loadingIndicator && (
            <Info
              severity="warning"
              title={'Oh no, there are no sales matching to your filter'}
              content={'There are no sales matching to your filter. Please verify your inputs to generate report.'}
            />
          )
        ))
      )}
      {dateLimitPopup && (
        <DateLimitDialog
          handleClose={() => {
            setDateLimitPopup(false);
          }}
        />
      )}
    </div>
  );
}

export default withConsoleBase(ReturnedItemSummary);
