import React, { createContext, useState } from 'react';
import { useLocation } from 'react-router';

export const selectedTabContext = createContext();

export default function SelectedTabProvider(props) {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location.pathname.split('/')[2] || 'dashboard');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [sections, setSections] = useState({
    reports: true,
    management: false,
    operations: true,
  });

  return (
    <selectedTabContext.Provider
      value={{ selectedTab, setSelectedTab, isMenuOpen, setMenuOpen, sections, setSections }}
    >
      {props.children}
    </selectedTabContext.Provider>
  );
}
