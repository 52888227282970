import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import DeviceService from '../../services/DeviceService';
import AlertHelper from '../../helpers/AlertHelper';
import { DialogActions } from '@mui/material';

export default function CreateDevicesDialog(props) {
  const [machineName, setMachineName] = useState('');
  const [machineNumber, setMachineNumber] = useState('');
  const [macId, setMacId] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const { toggleAddTab, setErrorMsg, setSuccessMsg } = props;
  const handleAddDevice = async () => {
    const data = {
      machine_name: machineName,
      machine_number: machineNumber,
      mac_id: macId,
      unique_id: uniqueId,
    };
    try {
      await DeviceService.addDevice(data);
      AlertHelper.setShortMessage(setSuccessMsg, 'Device added successfully');
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
    }
    toggleAddTab();
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Connect to a Device</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Machine Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Machine Name"
                variant="outlined"
                size="small"
                fullWidth
                value={machineName}
                onChange={(e) => {
                  setMachineName(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Machine Number</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Machine Number"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                value={machineNumber}
                onChange={(e) => {
                  setMachineNumber(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Mac ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Mac Id"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                value={macId}
                onChange={(e) => {
                  setMacId(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Unique ID</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="12 Digit Unique Id"
                variant="outlined"
                size="small"
                fullWidth
                className={styles.numberInput}
                value={uniqueId}
                onChange={(e) => {
                  setUniqueId(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={toggleAddTab} className={styles.closeBtn}>
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={machineName === '' || machineNumber === '' || macId === '' || uniqueId.length !== 12}
              onClick={handleAddDevice}
            >
              Save
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
