import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './styles.module.css';
import CloseIcon from '@material-ui/icons/Close';
import ShopService from '../../services/ShopService';
import { DialogActions } from '@mui/material';
// import AlertHelper from '../utils/Alert/AlertHelper';

export default function AddShopDialog(props) {
  const { toggleAddTab, updateShops } = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const handleAddShop = async () => {
    const data = {
      shop_name: name,
      mobile_no: phone,
    };
    await ShopService.setShop(data);
    updateShops();
    toggleAddTab();
  };
  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headSec}>
          <h2 className={styles.editTitle}>Create new shop</h2>
          <CloseIcon onClick={toggleAddTab} />
        </div>
        <div className={styles.inputSec}>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Shop Name</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Shop Name"
                variant="outlined"
                size="small"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container className={styles.inputRow} alignItems="center">
            <Grid item xs={4} sm={4} spacing={2} justify="flex-end" className={styles.inputLabelContainer}>
              <h3 className={styles.inputLabel}>Shop Mobile</h3>
            </Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                label="Shop Mobile"
                variant="outlined"
                size="small"
                type="number"
                className={styles.numberInput}
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.footSec}>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={toggleAddTab}
              className={styles.closeBtn}
            >
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ backgroundColor: '#00a65a' }}
              disabled={name === '' || phone === ''}
              onClick={handleAddShop}
            >
              Create shop
            </Button>
          </DialogActions>
        </div>
      </div>
    </div>
  );
}
