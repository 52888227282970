import React, { useState } from 'react';
import { Box, Button, DialogActions, Grid, TextField } from '@mui/material';
import styles from './styles.module.css';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import IconButton from '@mui/material/IconButton';
import AlertHelper from '../../helpers/AlertHelper';
import TableService from '../../services/TableService';
import Loader from '../utils/Loading';

export default function TableDetailsDialog({ table, handleClose, handleSuccess, setErrorMsg, setSuccessMsg }) {
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState(table || {});
  const [chair, setChair] = useState('');

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#afcdf0' : '#afcdf0',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleActionButton = async () => {
    setLoading(true);
    try {
      const updatedTable = await TableService.updateTables(tableData.id, tableData);
      AlertHelper.setShortMessage(setSuccessMsg, 'Table updated successfully');
      handleSuccess(updatedTable);
    } catch (error) {
      AlertHelper.setShortMessage(setErrorMsg, error.message);
      handleClose();
    }
    setLoading(false);
  };

  const onAddChairClicked = () => {
    tableData.chairs ||= [];
    tableData.chairs.push({ name: chair });
    setChair('');
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <div className={styles.popUp}>
        <div className={styles.contentWrapper}>
          <div className={styles.headSec}>
            <h2 className={styles.editTitle}>Table Details</h2>
            <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
          <div className={styles.inputSec}>
            <div>
              <Grid container alignItems="center">
                <Grid item sm={3}>
                  <h6 className={styles.tableName}>Table Name</h6>
                </Grid>
                <Grid item sm={9}>
                  <TextField
                    label="Table Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    type="text"
                    defaultValue={tableData.name}
                    onChange={(e) => {
                      setTableData({ ...tableData, name: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              <Box sx={{ width: '100%', marginTop: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} alignItems={'center'}>
                  {tableData.chairs.map((chair, index) => (
                    <>
                      <Grid item xs={3}>
                        <h6 className={styles.tableName}>{`Chair ${index + 1}`}</h6>
                      </Grid>
                      <Grid item xs={8}>
                        <Item>{chair.name} </Item>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon
                            onClick={() => {
                              tableData.chairs.splice(index, 1);
                              setTableData({
                                ...tableData,
                                chairs: tableData.chairs,
                              });
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </Box>
              <Grid container className={styles.inputData} style={{ marginTop: 10 }} alignItems="center">
                <Grid item xs={3}>
                  <h6 className={styles.tableName}>New Chair</h6>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Chair Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={chair}
                    type="text"
                    onChange={(e) => {
                      setChair(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={1} margin={'auto'}>
                  <Button fullWidth edge="end" aria-label="delete">
                    <LibraryAddIcon disabled={!tableData.chair} onClick={onAddChairClicked} />
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.footSec}>
            <DialogActions>
              <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ backgroundColor: '#00a65a' }}
                disabled={!tableData.name}
                onClick={handleActionButton}
              >
                Update
              </Button>
            </DialogActions>
          </div>
        </div>
      </div>
    </>
  );
}
