import React, { useState, useEffect } from "react";
import { TextField, Autocomplete } from "@mui/material";
import ItemService from "../../../services/ItemService";

const ItemSearchSelect = ({ filterItem, setFilterItem }) => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getItems = async () => {
      setLoading(true);
      const res = await ItemService.getItems();
      setItems(res || []);
      setLoading(false);
    };
    getItems();
  }, []);

  const handleSearch = (event, value) => {
    if (!value) {
      setFilteredItems([]);
      return;
    }
  
    const searchTerm = value.toLowerCase();
  
    let matchedItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm)
    );
  
    matchedItems = matchedItems.sort((a, b) => {
      const aLower = a.name.toLowerCase();
      const bLower = b.name.toLowerCase();
  
      if (aLower.startsWith(searchTerm) && !bLower.startsWith(searchTerm)) {
        return -1;
      }
      if (!aLower.startsWith(searchTerm) && bLower.startsWith(searchTerm)) {
        return 1;
      }
      return aLower.localeCompare(bLower); 
    });
  
    setFilteredItems(matchedItems.slice(0, 10));
  };
  

  return (
    <Autocomplete
      options={['All', ...filteredItems.length > 0 ? filteredItems : items.slice(0, 10)]}
      getOptionLabel={(option) => (option === "All" ? "All" : option.name)}
      value={items.find((item) => item.id === filterItem) || null}
      fullWidth
      disableClearable
      sx={{
        "& .MuiOutlinedInput-root": { 
          padding: "0",
          borderRadius: "4px",
        },
        "& fieldset": {
          borderColor: "rgba(0, 0, 1, 0.3)  !important",
        },
        "& .MuiOutlinedInput-root:hover fieldset": {  
            borderColor: "black !important",
        },
        "& .Mui-focused fieldset": {
            borderColor: "black !important",
        },
        ".MuiIconButton-root": {
          padding:"0",
        },
        ".MuiOutlinedInput-root .MuiAutocomplete-input": {
          padding: "12px",
          width: "90px",
          fontSize: "14px",
          height: "23px",
        },
      }}
      onInputChange={handleSearch}
      onChange={(event, newValue) => setFilterItem(newValue ? newValue.id : null)}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          variant="outlined"
          color="primary"
          placeholder="Search Item..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ItemSearchSelect;
